<!-- Show as dropdown -->
<ng-container *ngIf="!showInline">
    <button #toggleBtn class="toolbar-button toggle-button">
        <i class="material-icons color-c2a">filter_list</i>
        <span>Filtrering</span>

        <!-- <span class="filter-count" *ngIf="activeFilterCount">{{activeFilterCount}}</span> -->
    </button>

    <dropdown-menu #dropdownMenu [trigger]="toggleBtn" [closeOnTab]="false" [closeOnClick]="false">
        <ng-template>
            <section class="dropdown-content" tabindex="-1" uniAutoFocus>
                <ng-container *ngTemplateOutlet="inputElements"></ng-container>
                <button (click)="dropdownMenu?.hide()" class="secondary close-button mt-2">Lukk</button>
            </section>
        </ng-template>
    </dropdown-menu>
</ng-container>

<!-- Show inline -->
<section *ngIf="showInline" class="inline-inputs flex align-center">
    <ng-container *ngTemplateOutlet="inputElements"></ng-container>
</section>

<!-- Reusable template container the inputs -->
<ng-template #inputElements>
    <ng-container *ngFor="let input of inputs">
        <rig-checkbox
            *ngIf="input.type === 'Boolean'; else inputWithLabel"
            [(ngModel)]="input.value"
            (change)="onSwitch(input)"
        >
            {{ input.label }}
        </rig-checkbox>

        <ng-template #inputWithLabel>
            <label class="uni-label label-above">
                <div class="label-group flex align-center justify-between">
                    {{ input.label }}
                    <label class="range-button flex align-center" *ngIf="!!input.range">
                        <input type="checkbox" (click)="flipRange(input)" [ngModel]="input.range == 'on'" />
                        Fra og til
                    </label>
                </div>

                <autocomplete
                    *ngIf="!!input.autoCompleteOptions && input.range !== 'on'"
                    [options]="input.autoCompleteOptions"
                    [(value)]="input.value"
                    (valueChange)="onDimSelected(input)"
                >
                </autocomplete>

                <uni-select
                    *ngIf="input.type == 'Dropdown'"
                    [value]="input.value"
                    [items]="input.options"
                    [config]="input.config"
                    (valueChange)="onDropdownSelected($event, input)"
                >
                </uni-select>

                <range-input
                    [input]="input"
                    *ngIf="input.range === 'on'"
                    (valueChange)="onRangeSelected(input, $event)"
                >
                </range-input>

                <uni-datepicker
                    *ngIf="input.type == 'Date'"
                    [(value)]="input.value"
                    (valueChange)="onDateChanged(input)"
                >
                </uni-datepicker>

                <numeric-input
                    *ngIf="input.type == 'Number' && !input.autoCompleteOptions"
                    [(value)]="input.value"
                    (valueChange)="onNumericChanged(input)"
                >
                </numeric-input>

                <button *ngIf="!input.hasEditor" class="input-style" (click)="onInputClick(input)">
                    {{ input.text }}
                </button>
            </label>
        </ng-template>
    </ng-container>
</ng-template>
